import { Reducer } from 'redux'
import {
  ALREADY_SENT_EVENT,
  METRIC_USER_REGISTERED,
  RESET_DEFAULT_EVENTS,
  MetricTypes,
  START_VERIFICATION,
  USER_CREATED_CONTACT,
  RESET_PAYMENT_EVENT,
} from 'actions/metric/metricAction'
import { MetricEventSend } from 'components/metric/metric.types'
import {
  LocalStorageActionTypes,
  RESTORE_PARTIAL_STATE,
} from 'actions/system/localStorageAction'
import {
  SOCKET_RECEIVE_DATA,
  SocketContent,
  SocketData,
  SocketReceiveDataTypes,
} from 'actions/socket/socketReceiveDataAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { filterCometMessages } from 'reducers/functions'
import { createVipStatusChannel } from 'functions/comet'

export interface MetricState {
  isNewUser: boolean
  isStartVerification: boolean
  isCreateContact: boolean
  isAlreadySentEventRegistration: boolean
  isAlreadySentEventVerification: boolean
  isAlreadySentEventPhotoUpload: boolean
  isAlreadySentEventCreateContact: boolean
  isSendPaymentEvent: boolean
}

const initialState: MetricState = {
  isNewUser: false,
  isStartVerification: false,
  isCreateContact: false,
  isAlreadySentEventRegistration: false,
  isAlreadySentEventVerification: false,
  isAlreadySentEventPhotoUpload: false,
  isAlreadySentEventCreateContact: false,
  isSendPaymentEvent: false,
}

/**
 * Предполагаем в этом редьюсере может быть не только Яндекс Метрика
 * @param state
 * @param action
 */
export const metricReducer: Reducer<MetricState, MetricTypes> = (
  state = initialState,
  action: MetricTypes | LocalStorageActionTypes | SocketReceiveDataTypes
): MetricState => {
  switch (action.type) {
    case METRIC_USER_REGISTERED:
      return {
        ...state,
        isNewUser: true,
      }

    case ALREADY_SENT_EVENT:
      switch (action.event) {
        case MetricEventSend.RegistrationEvent:
          return {
            ...state,
            isAlreadySentEventRegistration: true,
          }

        case MetricEventSend.Verification:
          return {
            ...state,
            isAlreadySentEventVerification: true,
          }

        case MetricEventSend.CreateContact:
          return {
            ...state,
            isAlreadySentEventCreateContact: true,
          }

        case MetricEventSend.PhotoUploadSuccess:
          return {
            ...state,
            isAlreadySentEventPhotoUpload: true,
          }

        default:
          return state
      }

    case START_VERIFICATION:
      return {
        ...state,
        isStartVerification: true,
      }

    case USER_CREATED_CONTACT:
      return {
        ...state,
        isCreateContact: true,
      }

    case SOCKET_RECEIVE_DATA:
      return defaultPromiseReducer(state, action, undefined, () => {
        const vipArray = filterCometMessages(
          (action.result as unknown) as SocketData<SocketContent>[],
          createVipStatusChannel(action.userId)
        )

        if (vipArray && vipArray.length > 0) {
          return {
            isSendPaymentEvent: true,
          }
        }

        return state
      })

    case RESET_PAYMENT_EVENT:
      return {
        ...state,
        isSendPaymentEvent: false,
      }

    case RESET_DEFAULT_EVENTS:
      return { ...initialState }

    case RESTORE_PARTIAL_STATE:
      if (action.name === nameReducer) {
        return {
          ...state,
          ...action.partialState.metric,
        }
      }

      return state

    default:
      return state
  }
}

const nameReducer = 'metric'
